import { useState, useMemo, useCallback } from 'react';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { MediaSharedClassNames } from '@atlaskit/editor-common/styles';

import { useInlineComments } from '@confluence/inline-comments-hooks';
import { useUnreadInlineComments } from '@confluence/unread-comments';
import { getNextMarkerRef } from '@confluence/comments-util/entry-points/domUtils';
import type { Comment } from '@confluence/inline-comments-common/entry-points/inlineCommentsTypes';
import { useInlineCommentQueryParams } from '@confluence/comment';
import {
	checkElementExpandInEditor,
	scrollToComment,
	calculateElementOffset,
} from '@confluence/comments-util';
import { useBooleanFeatureFlag } from '@confluence/session-data';

type UseCommentNavigationProps = {
	scrollableContainerSelector: string;
	pageId: string;
	isEditor?: boolean;
	onNavigationClick: (nextMarkerRef: string) => void;
};

type useInlineCommentNavigationType = {
	currentCommentIndex: number;
	goToNextInlineComment: () => void;
	goToPrevInlineComment: () => void;
	setCurrentlyNavigatedComment: (currentComment: Comment) => void;
	commentsCount: number;
};

export const useInlineCommentNavigation = ({
	scrollableContainerSelector,
	pageId,
	isEditor,
	onNavigationClick,
}: UseCommentNavigationProps): useInlineCommentNavigationType => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [{ unresolvedInlineComments }] = useInlineComments();
	const [{ unreadCommentsListState }] = useUnreadInlineComments();
	const [currentCommentIndex, setCurrentCommentIndex] = useState(0);
	const { removeCommentQueryParams } = useInlineCommentQueryParams();
	const isCommentsOnMediaBugFixEnabled = useBooleanFeatureFlag(
		'confluence.frontend.comments-on-media-bug-fix',
	);

	const getCommentIndex = useCallback(
		(currentMarkerRef: string) => {
			if (unresolvedInlineComments && unresolvedInlineComments.length > 0) {
				return unresolvedInlineComments.findIndex((markerRef) => markerRef === currentMarkerRef);
			}
		},
		[unresolvedInlineComments],
	);

	const navigateToComment = useCallback(
		(action: 'next' | 'previous') => {
			if (unresolvedInlineComments.length < 2) {
				return;
			}

			// Clear the focusedCommentId queryParams so that reply does not opens up if any...
			removeCommentQueryParams();

			const nextMarkerRef = getNextMarkerRef(action, currentCommentIndex, unresolvedInlineComments);

			const currentCommentId = unresolvedInlineComments[currentCommentIndex];

			if (!nextMarkerRef) {
				return;
			}

			const nextCommentElement = document.getElementById(nextMarkerRef);
			const previousCommentElement = document.getElementById(currentCommentId);

			if (nextCommentElement) {
				checkElementExpandInEditor(nextCommentElement);
			}

			if (nextMarkerRef) {
				onNavigationClick(nextMarkerRef);
			}

			const previousOffset = previousCommentElement
				? calculateElementOffset(previousCommentElement)
				: 0;

			const offset = calculateElementOffset(nextCommentElement);

			const scrollableContainer: HTMLElement = document.querySelector(
				`${scrollableContainerSelector}`,
			) as HTMLElement;

			const differenceOffset = offset - previousOffset;
			scrollableContainer.scrollBy(0, differenceOffset);

			scrollToComment(nextCommentElement, previousCommentElement, true);

			if (nextCommentElement) {
				// As we navigate inline comments with arrow icons, we want to update the cursor location to where the open inline comment is
				// unless it is a media node in which case, we want our selection to be the media node element.
				if (isCommentsOnMediaBugFixEnabled) {
					const media = nextCommentElement.querySelector(
						`.${MediaSharedClassNames.MEDIA_CONTAINER}`,
					);
					if (media) {
						window.getSelection()?.setPosition(media);
					} else {
						window.getSelection()?.setPosition(nextCommentElement);
					}
				} else {
					window.getSelection()?.setPosition(nextCommentElement);
				}
			}

			createAnalyticsEvent({
				type: 'sendUIEvent',
				data: {
					action: 'clicked',
					actionSubject: 'navigate',
					actionSubjectId: 'inlineCommentArrowsNavigate',
					attributes: {
						unreadCommentCount: unreadCommentsListState?.length,
					},
					objectType: 'inlineComment',
					objectId: pageId,
					source: isEditor ? 'editPageScreen' : 'renderPageScreen',
				},
			}).fire();
		},
		[
			createAnalyticsEvent,
			currentCommentIndex,
			isEditor,
			onNavigationClick,
			pageId,
			removeCommentQueryParams,
			scrollableContainerSelector,
			unreadCommentsListState?.length,
			unresolvedInlineComments,
			isCommentsOnMediaBugFixEnabled,
		],
	);

	const setCurrentlyNavigatedComment = useCallback(
		(currentComment: Comment) => {
			const inlineMarkerRef = currentComment?.location?.inlineMarkerRef ?? '';
			const currentMarkerRefIndex = getCommentIndex(inlineMarkerRef);
			currentMarkerRefIndex && setCurrentCommentIndex(currentMarkerRefIndex);
		},
		[getCommentIndex],
	);

	const goToNextInlineComment = useCallback(() => {
		navigateToComment('next');
	}, [navigateToComment]);
	const goToPrevInlineComment = useCallback(
		() => navigateToComment('previous'),
		[navigateToComment],
	);

	return useMemo(
		() => ({
			currentCommentIndex,
			goToNextInlineComment,
			goToPrevInlineComment,
			setCurrentlyNavigatedComment,
			commentsCount: unresolvedInlineComments.length,
		}),
		[
			currentCommentIndex,
			goToNextInlineComment,
			goToPrevInlineComment,
			setCurrentlyNavigatedComment,
			unresolvedInlineComments.length,
		],
	);
};
