import FeatureGates from '@atlaskit/feature-gate-js-client';

import { useSessionData } from '@confluence/session-data';

export const SUGGESTED_COMMENT_PROMPTS_EXP = 'cc_suggested_inline_comment_replies';

//  Eligibility hook for suggested comment reply prompts experiment: https://hello.atlassian.net/wiki/spaces/Comments/pages/3579415601/Project+Poster+EXP+Suggested+Comment+Prompts+for+Inline+Comment+Replies
export const useGetCommentReplyPromptsEligibility = (): boolean => {
	const { isAdminHubAIEnabled } = useSessionData();
	const isUserInVariableCohort: boolean =
		FeatureGates.getExperimentValue<string>(SUGGESTED_COMMENT_PROMPTS_EXP, 'cohort', 'control') ===
		'test';

	return isUserInVariableCohort && isAdminHubAIEnabled;
};
