import React, { useCallback, useEffect, useState, useRef } from 'react';
import type { FC, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl-next';
import Mousetrap from 'mousetrap';
// We have deprecated unstated. Please use react-sweet-state instead
// eslint-disable-next-line no-restricted-imports
import { Subscribe } from 'unstated';
import { useIntl } from 'react-intl';

import CrossIcon from '@atlaskit/icon/glyph/cross';
import { N90 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip/Tooltip';

import { calculateElementOffset, scrollTargetIntoViewIfNeeded } from '@confluence/comments-util';
import {
	ReactionsProvider,
	useCommentContentContext,
	useCommentContentDispatchContext,
} from '@confluence/comment-context';
import { CommentWarningDialog } from '@confluence/comment-dialogs';
import { useBooleanFeatureFlag } from '@confluence/session-data';
import { DialogsStateContainer } from '@confluence/dialogs';
import { Attribution, ErrorBoundary } from '@confluence/error-boundary';
import {
	SidebarContainer,
	CloseButtonContainer,
	CloseButton,
	TopContainer,
} from '@confluence/inline-comments-common/entry-points/styled';
import type { Comment } from '@confluence/inline-comments-common/entry-points/inlineCommentsTypes';
import {
	INLINE_COMMENTS_SHORTCUT,
	TOGGLE_INLINE_COMMENTS_SHORTCUT_EDITOR,
	ShortcutVisualizer,
	TOGGLE_INLINE_COMMENTS_SHORTCUT_RENDERER,
} from '@confluence/shortcuts';

import { i18n } from './inlineCommentConstants';
import { CommentNavigation } from './CommentNavigation';

export type CommentNavigationOptions = {
	commentData: Comment;
	showNavigation: boolean;
	onNavigationClick: (nextMarkerRef: string) => void;
};

type CommentSidebarProps = {
	pageId: string;
	onClose: ((hasContentChanged?: boolean) => void) | undefined;
	annotationElement?: HTMLElement | null;
	navigationOptions?: CommentNavigationOptions;
	isEditor?: boolean;
	isViewCommentMode?: boolean;
	children: ReactNode;
	sidebarOffset: number;
	commentId?: string;

	/**
	 * Should scroll comment sidebar into view after mounting?
	 * When creating comment: scroll the entire sidebar into view;
	 * When viewing comment: scroll the first comment into view only to cater for long comment threads.
	 */
	scrollIntoView?: boolean;
};

export const CommentSidebar: FC<CommentSidebarProps> = ({
	pageId,
	onClose,
	annotationElement,
	navigationOptions,
	isEditor,
	isViewCommentMode,
	children,
	sidebarOffset,
	commentId,
	scrollIntoView,
}) => {
	const [isRefSet, setRef] = useState<boolean>(false);
	const sidebarEl = useRef<HTMLDivElement | null>(null);
	const [newSidebarOffset, setSidebarOffset] = useState<number>(sidebarOffset);

	const { hasContentChanged } = useCommentContentContext();
	const { resetContentChanged } = useCommentContentDispatchContext();
	const isAutoScrollBugFixEnabled = useBooleanFeatureFlag(
		'confluence.frontend.fabric.editor.comments-on-media-autoscroll-in-editor',
	);

	useEffect(() => {
		let offset = 0;

		// To refactor: we should be able to use useCommentSidebarOffset hook higher up the component tree
		if (annotationElement && !isEditor) {
			let scrollParent: HTMLElement | null;

			if (isEditor) {
				scrollParent = document.querySelector('.fabric-editor-popup-scroll-parent');
				offset = calculateElementOffset(annotationElement, scrollParent);
			} else {
				/*
        There's two cases here: a view comment and a create comment.
        We need the correct scrollParent for each case.
        Since it's not the editor in this logical branch we should use #content as the scrollParent.
        */
				scrollParent = document.querySelector('#content');

				if (scrollParent) {
					let annotationOffset = 0;

					if (isViewCommentMode) {
						// If the sidebar is rendering a view comment, we can use the annotationElement directly.
						annotationOffset = calculateElementOffset(annotationElement);
					} else {
						/*
            If the sidebar is rendering a create comment the annotationElement is actually the wrapperDOM
            we need to find the actual highlight instead. We can find this by looking for the mark because
            there will always be a mark before the create comment button is clicked.
            */
						const actualHiglightElement: HTMLElement | null = scrollParent.querySelector(
							`[data-annotation-draft-mark="true"]`,
						);
						annotationOffset = calculateElementOffset(actualHiglightElement, undefined, true);
					}
					offset = annotationOffset + scrollParent.offsetTop;
				}
			}
			setSidebarOffset(offset);
		}
	}, [annotationElement, isEditor, isViewCommentMode]);

	useEffect(() => {
		// Scroll comments sidebar into view in editor
		// When creating comment: always scroll into view if needed (see CreateComment)
		// When viewing comment: only scroll into view if there's no focused comment or unread comment (see InlineComment)
		if (isAutoScrollBugFixEnabled && isRefSet && sidebarEl.current && isEditor && scrollIntoView) {
			const scrollParent = document.querySelector('.fabric-editor-popup-scroll-parent');

			const target = isViewCommentMode
				? // If viewing the comments, scroll the first comment into view only to cater for long comment threads
					sidebarEl.current.querySelector(`[data-comment-id="${commentId}"]`)
				: sidebarEl.current;

			if (scrollParent && target) {
				scrollTargetIntoViewIfNeeded(target, scrollParent);
			}
		}
	}, [isAutoScrollBugFixEnabled, isRefSet, scrollIntoView, commentId, isViewCommentMode, isEditor]);

	useEffect(() => {
		let mouseTrap: any;
		if (isRefSet && sidebarEl.current) {
			mouseTrap = new Mousetrap(sidebarEl.current);
			// prevent saving via mod+enter from also publishing the page in edit mode
			mouseTrap.bind(INLINE_COMMENTS_SHORTCUT, (event: Event) => {
				event.stopPropagation();
			});
		}

		return () => {
			if (mouseTrap) {
				mouseTrap.reset();
			}
		};
	}, [isRefSet]);

	const handlePropagation = (event: any) => {
		/**
		 * prevents an event bubbling up to removing focus
		 * from the highlight displaying the sidebar
		 */
		event.stopPropagation();
	};

	// I understand the appeal to dynamically generate things like this, but when strings are used as IDs or keys,
	// it's more important to be explicit about those keys than smart in code. If there's a bug with this component,
	// the id is the thing people will search for; and if it's dynamically generated, it's impossible to find.
	const getSidebarId = (isEditor?: boolean, isViewCommentMode?: boolean) => {
		if (isEditor && isViewCommentMode) {
			return 'editor-comments-sidebar';
		} else if (isEditor && !isViewCommentMode) {
			return 'editor-create-sidebar';
		} else if (!isEditor && isViewCommentMode) {
			return 'renderer-comments-sidebar';
		} else {
			return 'renderer-create-sidebar';
		}
	};

	const handleOnClose = useCallback(
		(dialogs: DialogsStateContainer) => {
			if (hasContentChanged) {
				dialogs.showModal(CommentWarningDialog, {
					onConfirm: () => {
						resetContentChanged();
						onClose && onClose(false);
					},
				});
			} else {
				onClose && onClose(hasContentChanged);
			}
		},
		[hasContentChanged, onClose, resetContentChanged],
	);

	const sidebarId = getSidebarId(isEditor, isViewCommentMode);

	const mode = isEditor ? 'edit' : 'view';

	const intl = useIntl();
	const closeCommentBox = intl.formatMessage(i18n.closeCommentBox);

	return (
		<ErrorBoundary attribution={Attribution.COLLABORATION}>
			<Subscribe to={[DialogsStateContainer]}>
				{(dialogs: DialogsStateContainer) => (
					<ReactionsProvider contentId={pageId}>
						<SidebarContainer
							offset={newSidebarOffset}
							onClick={handlePropagation}
							id={sidebarId}
							data-testid={sidebarId}
							data-cy={sidebarId}
							mode={mode}
							ref={(el) => {
								sidebarEl.current = el;
								setRef(true);
							}}
						>
							<TopContainer showNavigation={navigationOptions && navigationOptions.showNavigation}>
								{navigationOptions && navigationOptions.showNavigation && (
									<CommentNavigation
										pageId={pageId}
										commentData={navigationOptions.commentData}
										isEditor={isEditor}
										onNavigationClick={navigationOptions.onNavigationClick}
									/>
								)}
								<Tooltip
									content={
										<ShortcutVisualizer
											shortcut={
												isEditor
													? TOGGLE_INLINE_COMMENTS_SHORTCUT_EDITOR
													: TOGGLE_INLINE_COMMENTS_SHORTCUT_RENDERER
											}
											isEditorShortcut={isEditor}
											contentBefore={<FormattedMessage {...i18n.closeSidebarTooltip} />}
										/>
									}
									hideTooltipOnClick
									position="top"
								>
									<CloseButtonContainer>
										<CloseButton
											appearance="subtle"
											onClick={() => handleOnClose(dialogs)}
											onMouseDown={(e) => e.preventDefault()}
											testId="close-comment-sidebar-button"
											data-cy={`${isEditor ? 'editor' : 'renderer'}-close-${
												isViewCommentMode ? 'view' : 'create'
											}-comment`}
											iconBefore={
												<CrossIcon
													label={closeCommentBox}
													primaryColor={token('color.icon.subtle', N90)}
													size="small"
												/>
											}
										/>
									</CloseButtonContainer>
								</Tooltip>
							</TopContainer>
							{children}
						</SidebarContainer>
					</ReactionsProvider>
				)}
			</Subscribe>
		</ErrorBoundary>
	);
};
