import {
	createPageSegmentLoadMetric,
	EDITOR_INLINE_COMMENT_RENDER_SEGMENT_KEY,
	RENDERER_INLINE_COMMENT_RENDER_SEGMENT_KEY,
} from '@confluence/browser-metrics';

// Editor Inline Comments
export const EDITOR_INLINE_COMMENT_RENDER_METRIC = createPageSegmentLoadMetric({
	key: EDITOR_INLINE_COMMENT_RENDER_SEGMENT_KEY,
});

// Renderer Inline Comments
export const RENDERER_INLINE_COMMENT_RENDER_METRIC = createPageSegmentLoadMetric({
	key: RENDERER_INLINE_COMMENT_RENDER_SEGMENT_KEY,
});
