import React, { useEffect, useMemo } from 'react';
import type { FC } from 'react';

import { CommentNavigation as CommentNavigationCommon } from '@confluence/inline-comments-common';
import { NavigationArrowContainer } from '@confluence/inline-comments-common/entry-points/styled';
import type { Comment } from '@confluence/inline-comments-common/entry-points/inlineCommentsTypes';

import { useInlineCommentNavigation } from './hooks/useInlineCommentNavigation';

type CommentNavigationProps = {
	pageId: string;
	commentData: Comment;
	isEditor?: boolean;
	onNavigationClick: (nextMarkerRef: string) => void;
};

export const CommentNavigation: FC<CommentNavigationProps> = ({
	pageId,
	commentData,
	isEditor,
	onNavigationClick,
}) => {
	const scrollableContainerSelector = isEditor
		? '.fabric-editor-popup-scroll-parent'
		: '#content-body';
	const {
		currentCommentIndex,
		goToNextInlineComment,
		goToPrevInlineComment,
		setCurrentlyNavigatedComment,
		commentsCount,
	} = useInlineCommentNavigation({
		isEditor,
		scrollableContainerSelector,
		pageId,
		onNavigationClick,
	});

	useEffect(() => {
		setCurrentlyNavigatedComment(commentData);
	}, [commentData, setCurrentlyNavigatedComment]);

	return useMemo(
		() => (
			<NavigationArrowContainer>
				<CommentNavigationCommon
					allowShortcuts={Boolean(!isEditor)}
					goToNextInlineComment={goToNextInlineComment}
					goToPrevInlineComment={goToPrevInlineComment}
					currentCommentIndex={currentCommentIndex}
					commentsCount={commentsCount}
				/>
			</NavigationArrowContainer>
		),
		[commentsCount, currentCommentIndex, goToNextInlineComment, goToPrevInlineComment, isEditor],
	);
};
